
.carouselItem{
	position:relative;

	&__img{
		width:100%;
	}

	&__desc{

	}
}


.carousel-indicators{
	display: none;
}

