.search{

	&__block{
		padding:10px 0;
		display: none;
	}
	
	&__content{
		text-align: center;
	}

	&-submit{
	 width: 150px;
    padding: 10px;
    background: $searchButton;
    border: 3px solid #fff;
    border-radius: 5px;
    color: #fff;
    font-size: 0.9375rem;
    margin-left: 5px;

    	&:hover,&:focus{
    		opacity:0.8;
    	}
	}

	&__alert{
		font-size:1.5em;
		&-title{
			color:$pageBgHeader;
			&:hover,&:focus{
			color:$pageBgHeader;	
			}
		}
	}
}

.input{
	width: 60%;
    padding: 10px;
    border: 2px solid #eee;
    border-radius: 15px;
    font-size: 1.125rem;
    outline:none;

    @media (max-width:$screen-sm - 1px){
    	width:100%;
    	margin-bottom: 10px;
    }
}


.search-close{
	display:inline-block;
	cursor:pointer;

	&::before{
		content:'';
		display: block;
		width:40px;
		height:4px;
		background:$searchButton;
		transform:rotate(45deg);
		-moz-transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		-ms-transform:rotate(45deg);
		-o-transform:rotate(45deg);
	}

		&::after{
		content:'';
		display: block;
		width:40px;
		height:4px;
		background:$searchButton;
		transform:rotate(-45deg);
		-moz-transform:rotate(-45deg);
		-webkit-transform:rotate(-45deg);
		-ms-transform:rotate(-45deg);
		-o-transform:rotate(-45deg);
		position:relative;
		top:-4px;
	}
}
