.listGallery{
	&-link{
		display: block;
		height: 300px;
		transition:transform 0.3s linear;
		-webkit-transition:transform 0.3s linear;
		-moz-transition:transform 0.3s linear;
		-ms-transition:transform 0.3s linear;
		-o-transition:transform 0.3s linear;
		margin-bottom:30px;
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center center;



		@media (max-width:$screen-md - 1px){
			height:200px;
		}
	}

	&__box{
		height:100%;
		position:relative;
		
		&-text{
		font-size:2em;
		font-weight: 700;
		color:$galleryText;
		text-shadow:1px 1px 2px rgba(0,0,0,0.75);
		position:absolute;
		bottom:00px;
		left:0px;
		width:100%;
		padding:10px;
		background:rgba(0,0,0,0.75);

			@media (max-width:$screen-md - 1px){
				font-size:1.5em;
			}
		}
	}
}

.imageGallery{
	text-align:center;
	margin:0 auto;
	&__link{
		display:inline-block;
		margin:10px;
	}
}