.page{
	background:$pageBg;

	&__block{

	}

	&__title{
		font-size:3em;
		color:$colorTitle;
		padding-top: 15px;
    	padding-bottom: 30px;
    	text-align: center;
    	font-weight: 400;

    	@media (max-width:$screen-md - 1px){
    		padding-top:0px;
    		padding-bottom:10px;
    		font-size: 2.375em;
    	}

    	@media (max-width:$screen-sm - 1px){
    		font-size:1.75em;
    	}
	}

	&__content{
		padding-top:60px;
		padding-bottom:60px;
		font-size: 0.938em;

		img{
			max-width:100%;
			height:auto;
		}

		@media (max-width:$screen-md - 1px){
			padding-top:40px;
			padding-bottom:40px;
		}

		@media (max-width:$screen-sm - 1px){
			padding-bottom:30px;
			padding-top:30px;
		}
	}

	&__gallery{
		margin-top:30px;
		
		
		&-link{
			display: inline-block;
			margin:10px;
				&:hover,&:focus{
				text-decoration: none;
			}
			
		}

		&-title{
			text-align:center;
			margin-top:5px;
			font-size:0.813em;
			text-decoration: none;

		}

	}


	&__description{
		margin-bottom:60px;

		@media (max-width:$screen-md - 1px){
			margin-bottom:30px;
		}
	}

	&__columns{
		margin-top:60px;
		 -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
		
		@media (max-width:$screen-md - 1px){
		margin-top:30px;
		 -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
		}


	}

	&__banner{
		width:100%;
	}


}