.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus{
	color: $pagination;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus{

    background-color:$pagination;
     border-color: $pagination;	
}

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus{
    color: $pagination;
    background-color: #eeeeee;
}

.pagination > li > a, .pagination > li > span{
  
    color: $pagination;
}

.pagination{
	font-size:1.125em;
}