.mobileMenu__block{
	position:fixed;
	right:-250px;
	top:0;
	width:245px;
	background:$default-color;
	z-index:100;
	height:100%;
	padding:25px;
	padding-top:30px;
	-webkit-box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.3);
	-ms-box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.3);
	-o-box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.3);
	   overflow: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;



  



}

.mobileMenu{
	list-style-type:none;
	padding:0;
	margin:0;
	margin-top:10px;
	font-size:0.875em;
	padding-bottom:50px;
	outline:none;

	.menu__li{
		text-align: right;
		padding:20px 0;
		border-bottom:1px solid $buttonBorder;
	
		&:last-child{
			border-bottom:0;
		}

		&.active{
			.menu__a,.menu__a:hover,.menu__a:focus{
				color:$activeLink;

			}
		}
	}

	.menu__a{
		text-decoration: none;
		text-transform:uppercase;
		color:$menuText;
		font-weight: 500;
	}

	.dropdown-menu{
		font-size: 1em;
		position:relative;
	    padding: 0;
	    margin: 0;
	    list-style: none;
	    text-align: left;
	    background-color: $default-color;
	    border: 0;
	    box-shadow:none;
	    margin-top:10px;
	    left: 0px;
    	margin-bottom: 20px;
    	border-bottom:1px solid $buttonBorder;
		
		
		.menu__li{
		text-align: right;
		border-bottom:0;
		padding:15px 0;

		&:last-child{
			padding-bottom:20px;
		}
		
		.menu__a{
		padding:0;
		margin:0;
		text-transform: none;
		font-weight: 400;
		width:195px;
	

		&:hover,&:focus{
			background:none;
		}
		}
	}

	    .divider{
	    	display: none;
	    }

	}

}

.displayMenu{
	right:0;
}


