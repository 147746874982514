.recommended{
	background:$recomBg;
	padding-top:55px;
	padding-bottom:65px;

	@media (max-width:$screen-md - 1px){
		padding-top:45px;
		padding-bottom:35px;
	}

	@media (max-width:$screen-sm - 1px){
		padding-top:30px;
		padding-bottom:15px;
	}

	&__title{
		font-size:3em;
		color:$recomTxt;
		text-align: center;
		margin:0;
		font-weight: 400;

		@media (max-width:$screen-md - 1px){
			font-size:2.625em;
		}

		@media (max-width:$screen-sm - 1px){
			font-size:2.250em;
		}

	}

	&__images{
		margin-top:50px;

		@media (max-width:$screen-sm - 1px){
			margin-top:20px;
		}
	}

}

