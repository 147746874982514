.footer{
	background:$default-color;
	font-size:0.750em;
	color:$footerText;
	padding-top:30px;
	padding-bottom:50px;
	color:$colorNews;

	&__right{
		text-align: right;

		@media (max-width:$screen-sm - 1px){
			text-align: left;
			margin-top:30px;
		}
	}

	&__img{
		position:relative;
		left:10px;
		bottom:2px;

		@media (max-width:$screen-sm - 1px){
			position:static;
			display:block;
		}
	}
	.policy{
		margin-top:7px;
			a{
			text-decoration: underline;
			color:$colorNews;
			&:hover,&:focus{
				color:$colorNews;
			}
		}
	}
}