$default-color: #fff;
$wcagBg:#EEEEEE;
$menuText:#777777;
$activeLink:#005099;
$textColor:#666666;
$activeButton:#F5F5F5;
$activeButton2:$default-color;
$colorTitle:#005099;
$colorNews:#666666;
$contactHeader:$default-color;
$contactText:$default-color;
$footerBg:#909090;
$footerText:$default-color;
$pageBg:$activeButton;
$pageBgHeader:#005099;
$pageHeader:$default-color;
$pagination:$pageBgHeader;
$menuBorder:#CDD1D1;
$galleryText:$default-color;
$searchButton:#F0890F;
$menuBackground:$default-color;
$recomBg:$default-color;
$recomTxt:#005099;
$menuButton:#BCBCBC;
$buttonBorder:#C8D0D2;
$menuActive:#FFD42D;
$whiteForm:#fff;
$buttonColor:#131518;
$backgroundColor:#FFD42D;
$lang:#777;
$langActive:#005099;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;
//top section home
$topSection:$default-color;
//news widget background
$newsWidget:$activeButton;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  1025px !default;
$screen-lg:                  1200px !default;
