.contact {
  background: url('/media/default/contact.jpg') center center no-repeat;
  background-size: cover;

  &__block {
    background-size: cover;
    padding-top: 140px;
    padding-bottom: 60px;

    @media (max-width: $screen-md - 1px) {
      padding-top: 70px;
    }

    @media (max-width: $screen-sm - 1px) {
      padding-top: 60px;
    }

  }

  &__header {
    font-size: 2.875em;
    color: $contactHeader;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    margin-bottom: 80px;
    font-weight: 400;

    @media(max-width: $screen-md - 1px) {
      margin-bottom: 50px;
      font-size: 2.625em;
    }

    @media (max-width: $screen-sm - 1px) {
      font-size: 1.750em;
    }
  }

  &__text {
    font-size: 1.188em;
    color: $contactText;
    font-weight: 300;
    text-align: center;
    line-height: 160%;

    @media (max-width: $screen-md - 1px) {
      font-size: 1.063em;
    }

    &--phone, &--mail {
      display: block;
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: none;
        color: $contactText;
      }
    }

    &--phone {
      margin-top: 25px;
    }

    &--mail {
      @media(max-width: $screen-sm - 1px) {
        margin-top: 20px;
      }
    }
  }


  &__more{
    border-top:1px solid $whiteForm;
    margin-top:120px;
    padding-top:40px;
    text-align:center;

    @media (max-width:$screen-sm - 1px){
     margin-top:60px;
    }
  }

  &__moreButton{
    font-size:1em;
    display:inline-block;
    padding:20px 70px;
    border:1px solid $whiteForm;
    color:$whiteForm;

    &:hover,&:focus{
      color:$whiteForm;
      text-decoration: none;
    }
  }
}

.form {
  max-width: 600px;
  margin: 0 auto;

  &__header {
    text-transform: uppercase;
    color: $whiteForm;
    font-size: 1.6em;
    text-align: center;
    padding-bottom: 39px;
  }

  &__label {
    font-size: 1em;
    color: $whiteForm;
    font-weight: 400;
  }

  &__input {
    outline: 0;
    border: 1px solid $whiteForm;
    background: none;
    height: 45px;
    padding: 5px;
    color:$whiteForm;
    margin-bottom:19px;

    &:focus{
      box-shadow:none;
      outline:0;
      border-color:$whiteForm;
    }
  }

  &__textarea{
    outline: 0;
    border: 1px solid $whiteForm;
    background: none;
    padding: 5px;
    color:$whiteForm;
    margin-bottom:19px;
    max-width:600px;
    width:100%;
    height:100%;
    min-height:150px;
    max-height:150px;

    &:focus{
      box-shadow:none;
      outline:0;
      border-color:$whiteForm;
    }
  }

  &__button{
    display:inline-block;
    color:$buttonColor;
    background:$backgroundColor;
    text-align:center;
    outline:0;
    border:0;
    padding: 17px 60px;
    font-size: 1.1em;
    font-weight: bold;

    @media (max-width:$screen-sm - 1px){
      margin-top:20px;
    }
  }

  &__row{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @media (max-width:$screen-sm - 1px){
      display:block;
    }

  }

  &__agg{
    font-size:1em;
    font-weight:400;
    color:$whiteForm;
    margin-bottom:10px;
  }

  &__more{
    font-weight:bold;
    color:$whiteForm;

    &:hover,&:focus{
      color:$whiteForm;
    }
  }

  &__text{
    display:none;
  }

  &__required{
    color:$whiteForm;
    font-size:1em;
    margin-bottom:30px;
  }

}