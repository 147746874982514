.sprite { background: url('/media/default/sprite.png') no-repeat top left;  } 
.sprite.big { background-position: 0px 0px; width: 34px; height: 34px;  } 
.sprite.big:focus,.sprite.big--active { background-position: -39px 0px; width: 34px; height: 34px;  } 
.sprite.medium { background-position: -78px 0px; width: 34px; height: 34px;  } 
.sprite.medium:focus,.sprite.medium--active { background-position: -117px 0px; width: 34px; height: 34px;  } 
.sprite.small { background-position: -156px 0px; width: 34px; height: 34px;  } 
.sprite.small:focus,.sprite.small--active { background-position: -195px 0px; width: 34px; height: 34px;  } 
.sprite.contrast { background-position: -234px 0px; width: 34px; height: 34px;  } 
.sprite.contrast--active { background-position: -273px 0px; width: 34px; height: 34px;  } 
.sprite.maps { background-position: -312px 0px; width: 34px; height: 34px;  } 
.sprite.maps:focus,.sprite.maps--active { background-position: -351px 0px; width: 34px; height: 34px;  } 
.sprite.search { background-position: -390px 0px; width: 32px; height: 32px; position: relative; top: 2px; left: 2px;  } 
.sprite.search--wcag { background-position: -427px 0px; width: 32px; height: 32px;  } 