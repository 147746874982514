.sitemap{
	list-style-type: none;
	font-size:1.375em;
	.caret{
		display: none;
	}

	.divider{
		display:none;
	}

	ul{
		list-style-type: none;
	}


}
