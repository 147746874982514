.wcag-block{
	background:$wcagBg;
	padding-top:10px;
	padding-bottom:10px;
}

.wcag-navigation{
	float:right;

	@media (max-width:$screen-sm - 1px){
		float:none;
		width:290px;
		margin:0 auto;
	}


	&__block{
		float:left;

		&--right{
			margin-left:11px;
		
		}

		&--center{
		margin-left:24px;
		}
	}

	&__button{
		height:34px;
		width:34px;
		float:left;
		margin-left:5px;
	}
}

.logo{
	position: relative;
	top: 10px;
}

.logo__link{
	display:block;

}

.logo__block{
	float:left;
}

.logo__title{
	font-size:1.938em;
	line-height:91%;
	position:relative;
	font-weight:700;
	position:relative;
	top:19px;
	left:15px;

	@media (max-width:$screen-md - 1px){
		font-size: 1.625em;
	}


	@media (max-width:$screen-sm - 1px){
		font-size: 1.5em;
	}

	&--firstLine{
		color:$menuActive;
	}

	&--secondLine{
		color:$colorTitle;
	}
}

.logo-img{
	float:left;
	position:relative;
	top:5px;

	@media (max-width:$screen-md - 1px){
		max-height: 55px;
	}

	@media (max-width:$screen-sm - 1px){
		left:5px;
	}
}

.menu{

			background: $menuBackground;

			@media (max-width:$screen-md - 1px){
				height:80px;
				position:relative;
				
			}

	.menu__li{
	
		.menu__a{
			padding:0;
			color:$menuText;
			font-size: 0.85em;
			text-transform: uppercase;
			font-weight: 500;
			border-top:4px solid $default-color;
			padding-top:32px;
			padding-bottom:33px;
			margin-left: 30px;
			@media (max-width:1199px){
				margin-left:45px;
			}


			&:hover,&:focus,{
				color:$menuActive;
				background:transparent;
				border-top:4px solid $menuActive;
			}

		}

	}

.navbar-nav{
	@media (max-width:$screen-md - 1px){
		display: none;
	}
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus{
	background:transparent;
}

}

.hiddenlink{
    text-indent: -9999px;
    line-height: 0 !important;
    font-size: 0 !important;
}



.nav .open > a, .nav .open > a:hover, .nav .open > a:focus{
	border-color:$menuActive;
	color:$menuActive;
}



.navbar-nav .menu__li .dropdown-menu{
	font-size:1em;
	margin-top:-20px;
	min-width: 212px;
    margin:auto;
	left:0;
	right:0;
    top: 67px;
    border: 0;
    border-top: 3px solid #CDD1D1;
    margin: auto;
    box-shadow: none;
    padding: 0 15px 15px 15px;
    background:$default-color;

    .menu__li{
    	.menu__a{
    		    margin: 0;

			    font-weight: 400;
			    border: none;
			    text-transform:none;
			    color:$menuText;
                 padding: 22px 0px;
                 border-bottom: 1px solid $menuBorder;

			    &:hover,&:focus{
			    	color:$menuActive;
			    }
    	}

      &:last-child{
        .menu__a{
          border-bottom:0;
          padding-bottom:10px;
        }
      }
    }
}


.navbar-nav li .dropdown-menu .divider:nth-child(1) {
    border: 0;
}

.navbar-nav .menu__li .dropdown-menu .divider {
    background-color: $default-color;
    border-bottom: 1px solid $menuBorder;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: $menuBorder;
}

.navbar-nav .menu__li .dropdown-menu::before{
	content: "";
    display: block;
    width: 0;
    height: 0;
    background-color:$default-color;;
    position: absolute;
    top: -10px;
    left: 95px;
    padding: 7px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    border-top: 3px solid $menuBorder;
    border-left: 3px solid $menuBorder;

    @media (max-width:$screen-md - 1px){
    	display: none;
    }

}

.menu .menu__li.active{
	.menu__a{
		color:$menuActive;
		border-top:4px solid $menuActive;
	}
}

.menu__li.active.open{
	.dropdown-menu{
		.menu__li{
			.menu__a{
				color:$menuText;
				&:hover,&:focus{
					color:$menuActive;
				}
			}

			&.active{
				.menu__a{
					color:$menuActive;
				}
			}
		}
	}

}

.dropdown-menu > .menu__li > .menu__a:hover, .dropdown-menu > .menu__li > .menu__a:focus{
	color:$menuActive;
}

.menu__li.active.show .dropdown-menu .menu__li.active .menu__a{
color:$menuActive;
background:transparent;					
}




.navbar-right{
	margin-right:-15px;

	@media (max-width:$screen-md - 1px){
		margin-right: 0;
	}
}

.search-close{
	display:block;

	&::before{
		content:'';
		display:block;
		width:20px;
		height:3px;
		background:$menuActive;
	}
}

.navbar-toggle {
	position:absolute;
	right:0px;
	top:5px;

	@media (max-width:$screen-md - 1px){
		display: block;
	}

	@media (max-width:$screen-sm - 1px){
		right:-10px;
		top:10px;
	}



}


.showButton{
	display:block;
	position:absolute;
	right:25px;
	top:25px;
	cursor:pointer;
	.strap{
	width:28px;
	height:5px;
	background:$menuButton;
	margin-bottom:4px;
	display:block;
	}

	@media (min-width:$screen-md){
		display: none;
	}
}


.closeButton{
	position:relative;
	left:170px;
	width:30px;
	height:30px;
	&::before{
		content:'';
		display: block;
		width:30px;
		height:5px;
		background:$menuButton;
		transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		-moz-transform:rotate(45deg);
		-ms-transform:rotate(45deg);
		-o-transform:rotate(45deg);
	}
		&::after{
		content:'';
		display: block;
		width:30px;
		height:5px;
		background:$menuButton;
		transform:rotate(-45deg);
		-webkit-transform:rotate(-45deg);
		-moz-transform:rotate(-45deg);
		-ms-transform:rotate(-45deg);
		-o-transform:rotate(-45deg);
		position:relative;
		top:-5px;
	}
}



.show{
	display:block;
}

.show{
	.dropdown-menu{
		display: block;
	}
}

.menu__row{
	display:flex;

  @media (max-width:$screen-md - 1px){
    display:block;
  }
}