#skipLinks {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 100; }
  #skipLinks a {
    display: block;
    padding: 20px;
    font-size: 1.6em;
    color: #ffff00 !important;
    text-decoration: none;
    text-align: center;
    position: absolute;
   left: -999em;
    width: 334px; }
  #skipLinks a:focus {
    background: #231f20;
    left: 0; }