.section{


	&__title{
	
		&--news{
			color:$colorTitle;
			text-align: center;

			@media (max-width:$screen-sm - 1px){
				font-size: 2.250em;
			}
		}

		&--news-view{
			font-size: 1.625em;
			margin-top:40px;

			@media (max-width:$screen-sm - 1px){
				font-size:1.500em;
				margin-top:20px;
			}
		}

		&--list-view{
			font-size:1.625em;
		}

		&--single-news{
			font-size: 2em;
		}


	}

	&__text{

		&--news{
			margin-top:35px;

			@media (max-width:$screen-md - 1px){
				margin-top:15px;
			}
		}
	}

	&--news{
		background:$newsWidget;
		padding:35px;
		padding-bottom:65px;

		@media (max-width:$screen-md - 1px){
			padding:45px 0;
			padding-bottom:65px;
		
		}

		@media (max-width:$screen-sm - 1px){
			padding-top:30px;
			padding-bottom:40px;
		}
	}

	&__img{
		margin-top:35px;
		.img-responsive{
			width:100%;
		}

		@media (max-width:$screen-sm - 1px){
			margin-top: 25px;
		}
	}

	&__date{
		margin-top:7px;
		font-size: 0.938em;

		@media (max-width:$screen-sm - 1px){
			font-size: 0.875em;
		}

		&--news{
			margin-bottom:10px;
		}
	}

	&__list-news{
		margin-bottom:50px;

		.img-responsive{
			width:100%;

			@media (max-width:$screen-md - 1px){
				margin-bottom:20px;
			}
		}
	}

}

.button{
		&--news{
		margin-top:40px;

		@media (max-width:$screen-md - 1px){
			margin-top:25px;
		}

	}

	&--list{
		float:right;
		margin-top:20px;

		@media (max-width:$screen-md - 1px){
			float:none;
		}

	}
}

.page__content--news{
	padding-bottom:0;

}

.news{
	&__img{
		border:8px solid $default-color;
	}

	&__more{
		border-top:1px solid $menuBorder;
		margin-top:50px;
		padding-top:15px;
	}

	&__moreLink{
		color:$activeLink;
		font-size:0.938em;
		text-decoration:underline;

		&:hover,&:focus{
			color:$activeLink;
		}
	}

	&__text{
		margin-top:30px;
	}
}

