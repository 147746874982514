html,body{
	font-family: 'Roboto', sans-serif;
	font-weight:400;
	font-size:1em;
	color:$textColor;
@media(max-width:$screen-md - 1px){
		overflow-x: hidden;
	}


	a{
		color:$textColor;
		&:hover,&:focus{
			color:$textColor;
		}
	}
}

html{
	position:relative;

}



.movePage{
	right:245px;
	overflow-y:hidden;
	background:#000;
}


.normal_f{font-size:1em}
.medium_f{font-size:1.125em} 
.big_f{font-size:1.25em}


.section{
	background:$topSection;
	padding:60px 0;
	padding-bottom:80px;

	@media (max-width:$screen-sm - 1px){
		padding-top:30px;
		padding-bottom:40px;
	}

	&__title{
		font-size:3em;
		line-height: 120%;
		font-weight: 400;
			margin:0;

			@media (max-width:$screen-md - 1px){
				font-size: 2.625em;

			}

			@media (max-width:$screen-sm - 1px){
				font-size:1.750em;
			}

		&--top{
			text-align:center;

		}

	}

	&__text{
		font-size:0.938em;
		line-height: 150%;

		@media (max-width:$screen-sm - 1px){
			font-size: 0.875em;
		}



		&--top{
			text-align: center;
			margin-top:30px;
		}



	
	}


	&__link{
		display: block;
		text-decoration: none;
		color:$textColor;

		&:hover,&:focus{
			color:$textColor;
			text-decoration: none;

			.button{
				background:$activeButton;
				&--news,&--list{
				background:$activeButton2;
			}
			}
		}
	}
}

.button{
	display:block;
	font-size:0.938em;
	border:1px solid $activeLink;
	color:$activeLink;
	width:255px;
	line-height:120%;
	text-align:center;
	padding-top:20px;
	padding-bottom:20px;
	margin:0 auto;
	text-decoration:none;
	&:hover,&:focus{
		text-decoration: none;
		color:$activeLink;
		background:$activeButton;
	}

	&--top-section{
	margin-top:35px;
	}



}


.map{
	height:558px;

	@media (max-width:$screen-md - 1px){
		height:397px;
	}

	@media (max-width:$screen-md - 1px){
		height:340px;
	}
}


.blur{
	&.active{
		background:rgba(136,136,136,0.8);
		height:100vh;
		width:100%;
		position:fixed;
		z-index: 10;
	}
}


.ue{
	background:$default-color;
	padding: 0px;
	padding-bottom: 20px;
	text-align:center;

	&__img{
		margin:0 auto;
		display:block;
		max-width:70%;
	}

	&__text{
		font-size:0.9em;
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 7px;
		span{
			font-weight:bold;
		}
	}
}


.lang-top{
	float:left;
	text-align:center;
	margin-left: 20px;
	margin-top: 7px;

	@media (max-width:$screen-sm - 1px){
		display:none;
	}
}

.lang{

	&__link{
		display:inline-block;
		font-size:1.1em;
		font-weight:bold;
		color:$lang;
		padding:0 7px;
		text-transform:uppercase;
		border-right:2px solid $lang;

		&:hover,&:focus{
			text-decoration:none;
			color:$lang;
		}

		&:last-child{
			border-right:0;
		}

		&.active{
			color:$langActive;
		}

	}
}

.lang-mobile {
	display: none;

	@media (max-width: $screen-sm - 1px) {
	display: block;
	}
}